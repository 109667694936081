
import { defineComponent, SetupContext, computed, ref, PropType } from 'vue'

import { UserStatus } from '@myndshft/types-mc-api'
import { useFormatting } from '@/dependencies/formatting'
import { getV2Route } from '@/service/shared.http'

import { IUserDetails } from '../../../server/api/models/fe/user'
import { ITenantDetails } from '@myndshft/types-mc-api/src/response/tenant'

import { MyndshftPanel } from '@myndshft/panel'
import LastModified from '@/components/shared/last-modified/last-modified.vue'
import MyndshftToggle from '@/components/shared/toggle/toggle.vue'
import UserTenantDialog from '@/components/user/user-tenant-dialog.vue'

export const UserDetailsLabel = {
	name: 'User Name',
	status: 'User Status',
	isLocked: 'User Locked',
	email: 'User Email',
	phone: 'Phone',
	id: 'User ID',
	externalId: 'External ID',
}

export default defineComponent({
	props: {
		userDetails: Object as PropType<IUserDetails>,
		tenant: Object as PropType<ITenantDetails>,
		isEditingEmail: Boolean,
		isLoading: Boolean,
	},
	components: {
		MyndshftPanel,
		LastModified,
		MyndshftToggle,
		UserTenantDialog,
	},
	setup(props, context: SetupContext) {
		const user = computed(() => props.userDetails)
		const showTenantDialog = ref(false)
		const hasClientsAssociated = computed(
			() => !!props.userDetails?.clients?.length
		)

		const handleUpdateField = (key: string, value: string) => {
			context.emit('updateField', { key, value })
		}

		const onPhoneNumber = (phoneNumber: string) => {
			const { toPhoneNumber } = useFormatting()
			handleUpdateField('phone', toPhoneNumber(phoneNumber))
		}

		return {
			UserStatus,
			UserDetailsLabel,
			user,
			showTenantDialog,
			hasClientsAssociated,
			handleUpdateField,
			onPhoneNumber,
			getV2Route,
		}
	},
})
