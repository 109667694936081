
import { defineComponent, ref, watch, PropType } from 'vue'

import { IClientDetails } from '@server/api/models/fe/client'
import { ClientUniqueID } from '@myndshft/types-mc-api'

import { MyndshftPanel } from '@myndshft/panel'

interface IHeader {
	text: string
	value: string
	width?: string
}

interface IAutocompleteItem {
	text: string
	value: ClientUniqueID
}

export default defineComponent({
	props: {
		tenantClients: {
			type: Array as PropType<IClientDetails[]>,
			default: () => [],
		},
		userClients: {
			type: Array as PropType<IClientDetails[]>,
			default: () => [],
		},
		selectedClients: {
			type: Array as PropType<ClientUniqueID[]>,
			default: () => [],
		},
		isLoading: Boolean,
	},
	components: {
		MyndshftPanel,
	},
	setup(props) {
		const clientOptions = ref<IAutocompleteItem[]>([])

		const headers: IHeader[] = [
			{ text: 'Name', value: 'name' },
			{ text: 'Status', value: 'status' },
			{ text: '', value: 'save' },
		]

		const filterTenantClients = (
			userClientsList: IClientDetails[],
			tenantClientsList: IClientDetails[]
		) => {
			const userClientMap: { [key: string]: boolean } = {}
			for (const client of userClientsList) {
				userClientMap[client.id] = true
			}
			const filteredList: IAutocompleteItem[] = []
			for (const client of tenantClientsList) {
				if (!userClientMap[client.id]) {
					filteredList.push({
						text: client.name || `${client.firstName} ${client.lastName}`,
						value: client.id,
					})
				}
			}
			return filteredList
		}

		watch(
			() => [props.userClients, props.tenantClients],
			([uClients, tClients]) => {
				clientOptions.value = filterTenantClients(uClients, tClients)
			}
		)

		return {
			headers,
			clientOptions,
		}
	},
})
