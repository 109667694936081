
import { defineComponent, ref, watch, PropType } from 'vue'

import { IUserDetails } from '../../../server/api/models/fe/user'
import { UserDetailsLabel } from './user-details.vue'

import { MyndshftModal } from '@myndshft/modal'

export default defineComponent({
	props: {
		initialUser: Object as PropType<IUserDetails>,
		userChanges: Object as PropType<Partial<IUserDetails>>,
		isLoading: Boolean,
		showModal: Boolean,
	},
	components: {
		MyndshftModal,
	},
	setup(props) {
		const changes = ref<any>([])

		const shouldWarn = (fieldType: string) => {
			switch (fieldType) {
				case UserDetailsLabel.status:
				case UserDetailsLabel.email:
					return 'warn'
				default:
					return ''
			}
		}

		watch(
			() => [props.initialUser, props.userChanges],
			([prevUser, userUpdates]) => {
				const formattedChanges: any[] = []
				for (const key in userUpdates) {
					if (Object.prototype.hasOwnProperty.call(userUpdates, key)) {
						let displayName = key
						let previous = prevUser?.[key as keyof IUserDetails] || '-'
						let current = userUpdates[key as keyof IUserDetails] || '-'
						switch (key) {
							case 'name':
								displayName = UserDetailsLabel.name
								break
							case 'status':
								displayName = UserDetailsLabel.status
								break
							case 'isLocked':
								displayName = UserDetailsLabel.isLocked
								previous = prevUser?.[key as keyof IUserDetails]
									? 'Locked'
									: 'Unlocked'
								current = userUpdates[key as keyof IUserDetails]
									? 'Locked'
									: 'Unlocked'
								break
							case 'email':
								displayName = UserDetailsLabel.email
								break
							case 'phone':
								displayName = UserDetailsLabel.phone
								break
							default:
								displayName = key
						}
						formattedChanges.push({
							displayName,
							previous,
							current,
						})
					}
				}
				changes.value = formattedChanges
			}
		)

		return {
			changes,
			shouldWarn,
		}
	},
})
