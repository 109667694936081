
import { defineComponent, ref, onBeforeMount } from 'vue'
import { Alert, toast } from '@/dependencies/sweet-alert'
import { useRouter } from '@/utils/composables'

import {
	ClientUniqueID,
	TenantUniqueID,
	UserStatus,
	UserUniqueID,
} from '@myndshft/types-mc-api'
import { IUser, IUserDetails } from '../../../server/api/models/fe/user'
import { ITenantDetails } from '@myndshft/types-mc-api/src/response/tenant'
import { IClientDetails } from '@server/api/models/fe/client'
import {
	addUserToClient,
	fetchUser,
	removeUserFromClient,
	updateUserDetails,
} from '@/service/user.http'
import { fetchTenantClients } from '@/service/tenant.http'

import Clients from '@/components/user/clients/client.vue'
import UserDetails from '@/components/user/user-details.vue'
import UserDiffDialog from '@/components/user/user-diff-dialog.vue'
import V2Cta from '@/components/shared/v2-cta.vue'

export default defineComponent({
	components: {
		Clients,
		UserDetails,
		UserDiffDialog,
		V2Cta,
	},
	setup() {
		const router = useRouter()

		const isLoading = ref(true)
		const isEditingEmail = ref(false)
		const showConfirmEmailChange = ref(false)

		const title = 'Update User configuration'
		const id = router.currentRoute.params.id as UserUniqueID
		// USER DETAILS
		const initialUser = ref<IUserDetails>({} as IUserDetails)
		const partialUser = ref<any>({})
		const updateMask = ref<string[]>([])
		const user = ref<IUserDetails>({} as IUserDetails)
		const tenant = ref<ITenantDetails>({} as ITenantDetails)
		// USER CLIENTS
		const tenantClients = ref<IClientDetails[]>([])
		const selectedClients = ref<ClientUniqueID[]>([])

		const getUser = async () => {
			await fetchUser(id)
				.then(async (userRes: IUser) => {
					initialUser.value = { ...userRes.user }
					user.value = { ...userRes.user }
					tenant.value = userRes.tenant
					tenantClients.value = await fetchTenantClients(
						userRes.user.tenantId as TenantUniqueID
					)
				})
				.catch(error => {
					console.warn(error)
					toast({ type: Alert.ERROR, title: 'Failed to retrieve user' })
				})
				.finally(() => {
					isLoading.value = false
				})
		}

		const handleFieldUpdate = (event: {
			key: keyof IUserDetails
			value: any
		}) => {
			user.value = { ...user.value, [event.key]: event.value }
		}

		const createUpdates = () => {
			partialUser.value = {}
			updateMask.value = []
			for (const key in user.value) {
				if (
					user.value[key as keyof IUserDetails] !==
						initialUser.value[key as keyof IUserDetails] &&
					key !== 'clients'
				) {
					partialUser.value[key] = user.value[key as keyof IUserDetails]
					updateMask.value.push(key)
				}
			}
		}

		const checkUserChanges = () => {
			createUpdates()
			if (updateMask.value.length) {
				showConfirmEmailChange.value = true
			} else {
				toast({
					type: Alert.INFO,
					title: 'No changes to be made to user details',
				})
			}
		}

		const updateUser = async () => {
			isLoading.value = true
			isEditingEmail.value = false
			showConfirmEmailChange.value = false
			await updateUserDetails(id, partialUser.value, updateMask.value)
				.then(() => {
					getUser()
					toast({
						type: Alert.SUCCESS,
						title: 'Successfully updated User',
					})
				})
				.catch((err: any) => {
					if (err.response.status === 409 && err.response.data.message) {
						toast({
							type: Alert.ERROR,
							title: err.response.data.message,
						})
					} else {
						toast({
							type: Alert.ERROR,
							title: 'Unknown error occurred updating User',
						})
					}
				})
				.finally(() => {
					isLoading.value = false
				})
		}

		const handleUpdateTenant = (tenantId: string) => {
			user.value = { ...initialUser.value }
			user.value.tenantId = tenantId
			createUpdates()
			updateUser()
		}

		const handleAddClients = async () => {
			isLoading.value = true
			await addUserToClient(user.value.id, selectedClients.value)
				.then(() => {
					tenantClients.value.map(client => {
						if (selectedClients.value.includes(client.id)) {
							user.value?.clients?.push(client)
						}
					})
					toast({
						type: Alert.SUCCESS,
						title: 'Successfully added User to Client(s)',
					})
					selectedClients.value = []
				})
				.catch(err => {
					if (err.response.status === 409 && err.response.data.message) {
						toast({
							type: Alert.ERROR,
							title: err.response.data.message,
						})
					} else {
						toast({
							type: Alert.ERROR,
							title: 'Unknown error occurred updating User',
						})
					}
				})
				.finally(() => {
					isLoading.value = false
				})
		}

		const handleRemoveClient = async (clientId: ClientUniqueID) => {
			isLoading.value = true
			await removeUserFromClient(user.value.id, clientId)
				.then(() => {
					user.value.clients = user.value?.clients?.filter(
						client => client.id !== clientId
					)
					toast({
						type: Alert.SUCCESS,
						title: 'Successfully removed User from Client',
					})
				})
				.catch(err => {
					if (err.response.status === 409 && err.response.data.message) {
						toast({
							type: Alert.ERROR,
							title: err.response.data.message,
						})
					} else {
						toast({
							type: Alert.ERROR,
							title: 'Unknown error occurred updating User',
						})
					}
				})
				.finally(() => {
					isLoading.value = false
				})
		}

		onBeforeMount(() => {
			getUser()
		})

		return {
			isLoading,
			isEditingEmail,
			showConfirmEmailChange,
			title,
			id,
			user,
			tenant,
			initialUser,
			UserStatus,
			tenantClients,
			selectedClients,
			partialUser,
			getUser,
			checkUserChanges,
			updateUser,
			handleFieldUpdate,
			handleAddClients,
			handleRemoveClient,
			handleUpdateTenant,
		}
	},
})
