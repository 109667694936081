
import { defineComponent, ref, onBeforeMount, computed, PropType } from 'vue'
import { Alert, toast } from '@/dependencies/sweet-alert'

import { fetchTenantList } from '@/service/tenant.http'
import { ITenantDetails } from '@server/api/models/fe/tenant'

export default defineComponent({
	props: {
		showDialog: Boolean,
		userName: String,
		currentTenant: Object as PropType<ITenantDetails>,
	},
	setup(props, context) {
		const tenantOptions = ref<any>([])
		const selectedTenant = ref<any>(null)
		const page = ref(0)

		const isSameTenant = computed(() => {
			return selectedTenant.value?.value === props.currentTenant?.id
		})

		const handleCancel = () => {
			context.emit('close')
			page.value = 0
		}

		const handleNext = () => {
			if (page.value === 0) {
				page.value = 1
			} else {
				context.emit('save', selectedTenant.value.value)
				page.value = 0
				context.emit('close')
			}
		}

		const formatOptions = (tenants: ITenantDetails[]) => {
			return tenants.map(tenant => {
				return { text: tenant.name, value: tenant.id }
			})
		}

		onBeforeMount(() => {
			fetchTenantList()
				.then(response => {
					tenantOptions.value = formatOptions(response.tenants)
					selectedTenant.value = {
						text: props.currentTenant?.name,
						value: props.currentTenant?.id,
					}
				})
				.catch(() => {
					toast({
						type: Alert.ERROR,
						title: 'Unable to fetch tenant options',
					})
				})
		})

		return {
			tenantOptions,
			selectedTenant,
			page,
			isSameTenant,
			handleCancel,
			handleNext,
		}
	},
})
