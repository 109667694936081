
import { defineComponent } from 'vue'

import User from '@/components/user/user.vue'

export default defineComponent({
	components: {
		User,
	},
	setup() {
		return {}
	},
})
